<template>
  <div class="guest-actions" data-t="guest-actions">
    <StButton
      :label="t('header.loginButton')"
      type="gray"
      data-t="login-button"
      replace
      :to="{ query: { modal: 'login' } }"
    />
    <StButton
      v-if="isRegistrationVisible"
      :label="t('header.registrationButton')"
      data-t="registration-button"
      replace
      :to="{ query: { modal: 'register' } }"
    />
  </div>
</template>

<script setup lang="ts">
interface Props {
  isRegistrationVisible?: boolean
}
withDefaults(defineProps<Props>(), {
  isRegistrationVisible: true,
})
const { t } = useI18n()
</script>

<style scoped>
.guest-actions {
  position: relative;
  display: flex;
  gap: var(--spacing-150);
}
</style>
