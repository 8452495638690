<template>
  <header class="header" :class="{ landing: isLanding }" data-t="header">
    <NuxtI18nLink to="/" class="logo" data-t="logo">
      <img src="./images/logo.svg" alt="logotype" width="140" height="44" />
    </NuxtI18nLink>
    <nav class="links" :class="{ sports: isSportPage }">
      <NuxtI18nLink
        to="/casino"
        class="link"
        :class="{ active: isCasinoPage }"
        active-class="active"
        data-t="casino-link"
      >
        {{ t('header.casinoLink') }}
      </NuxtI18nLink>
      <div class="sports-links" :class="{ 'with-separators': isSportPage }">
        <NuxtI18nLink
          to="/sport"
          class="link"
          :class="{ active: isSportPage }"
          active-class="active"
          data-t="sport-link-vlvo"
        >
          {{ t('header.sportLink') }}
        </NuxtI18nLink>
        <div v-if="isSportPage" class="sports-sublinks">
          <NuxtI18nLink
            to="/sport/all"
            class="link small"
            active-class="active"
            data-t="sport-link-vlvo"
          >
            {{ t('header.line') }}
          </NuxtI18nLink>
          <NuxtI18nLink
            to="/sport/live"
            class="link small"
            active-class="active"
            data-t="sport-link-vlvo"
          >
            {{ t('header.liveNow') }}
          </NuxtI18nLink>
        </div>
      </div>
      <NuxtI18nLink
        to="/cybersport"
        class="link"
        :class="{ active: isCyberSportPage }"
        active-class="active"
        data-t="cyber-link"
      >
        {{ t('header.cybersportLink') }}
      </NuxtI18nLink>
    </nav>
    <GuestActions v-if="isLanding" :is-registration-visible="false" />
    <ClientOnly v-else-if="isAuthenticated">
      <UserActions />
    </ClientOnly>
    <ClientOnly v-else>
      <GuestActions />
    </ClientOnly>
  </header>
</template>

<script setup lang="ts">
import { useMatchLinkToRoute } from '@st/use/composables'
import { useUserStore } from '@st/user/stores/useUserStore'
import GuestActions from './parts/GuestActions.vue'
import UserActions from './parts/UserActions.vue'

defineProps<{
  isLanding?: boolean
}>()

const { t } = useI18n()

const { isAuthenticated } = storeToRefs(useUserStore())
const { isCasinoPage, isSportPage, isCyberSportPage } = useMatchLinkToRoute()
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;

  width: 100%;
  padding: var(--spacing-100) 0;

  background-color: var(--background-base);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 240px;
  margin-right: var(--spacing-200);

  @media screen and (width <= 1440px) {
    width: auto;
    margin-right: var(--spacing-500);
  }
}

.links {
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-300);

  &.sports {
    gap: var(--spacing-500);
  }
}

.link {
  font: var(--desktop-text-md-semibold);
  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }

  &.active {
    color: var(--text-link);
  }

  &.small {
    font: var(--desktop-text-sm-medium);
  }
}

.sports-links {
  position: relative;
  display: flex;
  gap: var(--spacing-200);
  align-items: center;

  &.with-separators {
    &::before,
    &::after {
      content: '';

      position: absolute;
      bottom: 4px;

      width: 1px;
      height: 16px;

      background-color: var(--border-secondary);
    }

    &::before {
      left: calc(var(--spacing-250) * -1);
    }

    &::after {
      right: calc(var(--spacing-250) * -1);
    }
  }
}

.sports-sublinks {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  padding-top: 1px; /* stylelint-disable-line */
}

.header.landing {
  padding: var(--spacing-125) 0;
  background-color: transparent;

  .logo {
    width: auto;
    margin-right: var(--spacing-500);
  }
}
</style>
